




















import Vue from "vue";

export default Vue.extend({
  name: "RiskSelectBox",
  props: ["text", "id", "choices", "supplement"],
  methods: {
    changeAnswerValue(value: number) {
      this.$store.commit("dementia/changeRiskAnswerValue", {
        id: this.$props.id,
        value: value
      });
    }
  },
  computed: {
    checkedScore() {
      return this.$store.state.dementia.riskAnswers[this.$props.id];
    }
  }
});
