




































import RiskSelectBox from "@/components/dementia/RiskSelectBox.vue";
import rootStore from "@/store";
import { questions } from "../../data/dementia";
import { DementiaState } from "@/types/dementia";
const state = rootStore.state as { dementia: DementiaState };
export default {
  name: "Risk",
  head: {
    title: {
      inner: "認知症かんたんチェック"
    }
  },
  components: { RiskSelectBox },
  data: function() {
    return {
      questions: questions()
    };
  },
  computed: {
    isAnswerComplete(): boolean {
      return !state.dementia.riskAnswers.includes(undefined);
    }
  }
};
